import VueMatomo from "vue-matomo";

// Matomo settings
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueMatomo, {
    host: "https://matomo.irtsysx.fr/",
    siteId: 13,
    router: nuxtApp.$router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
  });
});
